// src/components/Cart.js

import React, { useContext } from 'react';
import { CartContext } from '../contexts/CartContext';

const Cart = () => {
    const { cart, removeFromCart, clearCart } = useContext(CartContext);

    if (cart.length === 0) {
        return <div>Your cart is empty</div>;
    }

    return (
        <div className="cart">
            <h1>Your Cart</h1>
            <ul>
                {cart.map((item, index) => (
                    <li key={index}>
                        <img src={item.images[0]?.image} alt={item.name} />
                        <h2>{item.name}</h2>
                        <p>${item.price}</p>
                        <button onClick={() => removeFromCart(item.id)}>Remove</button>
                    </li>
                ))}
            </ul>
            <button onClick={clearCart}>Clear Cart</button>
            <button>Proceed to Checkout</button>
        </div>
    );
};

export default Cart;
